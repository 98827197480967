<template>
  <c-card class="cardClassDetailForm" title="상세" style="width: 1000px; max-width: 1000px !important">
    <template slot="card-button">
      <q-btn-group outline >
        <c-btn 
          v-if="editable" 
          label="적용" 
          icon="check"
          @btnClicked="accept" />
      </q-btn-group>
    </template>
    <template slot="card-detail">
      <div class="col-12">
        <c-text
          :disabled="true"
          label="총점수 (A x B)"
          name="total"
          v-model="total">
        </c-text>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <c-table
          ref="table1"
          :columns="grid1.columns"
          :data="grid1.data"
          :gridHeight="gridHeight"
          :isTitle="false"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          @rowClick="rowClick1"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <c-table
          ref="table2"
          :columns="grid2.columns"
          :data="grid2.data"
          :gridHeight="gridHeight"
          :isTitle="false"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          @rowClick="rowClick2"
        >
        </c-table>
      </div>
    </template>
  </c-card>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'jobRiskPop',
  props: {
    props: {
      type: Object,
      default: function() {
        return {
          row: [],
        }
      },
    },
    col: {
      type: Object,
      default: function() {
        return {}
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    editable: {
      type: Boolean,
      default: function() {
        return false
      },
    },
  },
  data() {
    return {
      grid1: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '작업부하(A)',
            align: 'center',
            sortable: false,
          },
          {
            name: 'code',
            field: 'code',
            label: '점수',
            align: 'center',
            style: 'width:50px',
            sortable: false
          },
        ],
        data: []
      },
      grid2: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '작업빈도(B)',
            align: 'center',
            sortable: false,
          },
          {
            name: 'code',
            field: 'code',
            label: '점수',
            align: 'center',
            style: 'width:50px',
            sortable: false
          },
        ],
        data: []
      },
      strength: 0,
      frequency: 0,
      total: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return '250px';
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      this.$comm.getComboItems('HEA_MUSCLE_STRENGTH').then(_result => {
        this.grid1.data = _result
      });
      this.$comm.getComboItems('HEA_MUSCLE_FREQUENCY').then(_result => {
        this.grid2.data = _result
      });
      // list setting
    },
    rowClick1(row) {
      this.strength = Number(row.code)
      if (this.frequency) {
        this.total = this.strength * this.frequency
      }
    },
    rowClick2(row) {
      this.frequency = Number(row.code)
      if (this.strength) {
        this.total = this.strength * this.frequency
      }
    },
    accept() {
      this.$emit('callback', { 
        total: this.total, 
        strength: this.strength, 
        frequency: this.frequency 
      });
    },
  }
};
</script>